import { AxiosError } from 'axios'
import useSWR from 'swr'
import { ApiPath } from '@/constants/Path'
import { getPopups } from '@/apis/noticeData'
import { AdminPopup } from '@/types/MetaContent'

export default function useGetPopups() {
  const { data, error, isLoading } = useSWR<AdminPopup[], AxiosError>(
    ApiPath.GET_POPUPS,
    async () => (await getPopups()).data
  )

  return { popups: data, error, isLoading }
}
