import { css } from '@emotion/react'

export enum BackgroundPathType {
  IMAGES = 'images',
  ICONS = 'icons',
}

export const STYLE_CLEAR_FIX = css`
  &::after {
    content: '';
    clear: both;
    display: table;
    overflow: hidden;
    width: 100%;
    height: 0;
    font-size: 0;
    line-height: 0;
  }
`

export const STYLE_MULTI_BACKGROUND = (
  name: string,
  pathType: BackgroundPathType = BackgroundPathType.IMAGES
) => css`
  background-image: url(${`${process.env.CDN_URI}/${pathType}/${name}.png`});
  background-image: url(${`${process.env.CDN_URI}/${pathType}/${name}.svg`}),
    none;
`

export const STYLE_SAFE_BACKGROUND_IMAGE = (
  name: string,
  width: number,
  height = width,
  pathType: BackgroundPathType = BackgroundPathType.IMAGES,
  size: 'contain' | 'cover' | '100%' = 'contain'
) => css`
  width: ${width}px;
  height: ${height}px;
  background-size: ${size};
  background-repeat: no-repeat;
  ${STYLE_MULTI_BACKGROUND(name, pathType)}
`

export const STYLE_SYSTEM_GRID_OUTSIDE = css`
  padding-left: 5%;
  padding-right: 5%;
`

export const STYLE_SYSTEM_GRID_INSIDE = css`
  padding-left: 6px;
  padding-right: 6px;
`
