import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'

const PREFIX = `${process.env.CDN_URI}/icons/`

export interface IconProps {
  type: IconTypeFile
  show?: boolean | any
  className?: string
  style?: CSSProperties
}

interface IconStyleProps {
  type: IconTypeFile
  width: string
  height: string
}

export enum IconTypeFile {
  IC_16_DOWNLOAD = 'ic_16_download',
  IC_16_FOLD = 'ic_16_fold',
  IC_16_UNFOLD = 'ic_16_unfold',
  IC_16_MORE = 'ic_16_more',
  IC_16_MORE_WHITE = 'ic_16_more_white',
  IC_16_MORE_YELLOW = 'ic_16_more_yellow',
  IC_16_MORE_SMALL = 'ic_16_more_small',
  IC_16_OUT_LINK = 'ic_16_outlink',
  IC_24_MENU = 'ic_24_menu',
  IC_24_PREV = 'ic_24_prev',
  IC_24_NEXT = 'ic_24_next',
  IC_24_CLOSE_WHITE = 'ic_24_close_white',
  IC_24_LINK = 'ic_24_link',
  IC_32_GOOGLEPLAY = 'ic_32_googleplay',
  IC_32_APPSTORE = 'ic_32_appstore',
  IC_48_PLUS = 'ic_48_plus',
  IC_48_FOLD = 'ic_48_fold',
  IC_48_ARROW_RIGHT = 'ic_48_arrow_right',
}

function Icon({ type, className, show = true, style }: IconProps) {
  if (!show) return null

  let width: string
  const height: string = type.split('_')[1]

  switch (type) {
    case IconTypeFile.IC_16_MORE:
    case IconTypeFile.IC_16_MORE_WHITE:
      width = '40'
      break
    case IconTypeFile.IC_24_PREV:
    case IconTypeFile.IC_24_NEXT:
      width = '84'
      break
    default:
      width = height
      break
  }

  return (
    <Styled.Icon
      type={type}
      className={className}
      width={width}
      height={height}
      style={style}
    />
  )
}

const Styled = {
  Icon: styled.i<IconStyleProps>`
    display: inline-block;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    /* Compatibility */
    background-image: url('${({ type }) => `${PREFIX}${type}.svg`}');
    /* SVG */
    background-image: url('${({ type }) => `${PREFIX}${type}.svg`}'), none;
    background-size: ${({ width }) => width}px ${({ height }) => height}px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
  `,
}

export default Icon
