import { useRouter } from 'next/router'
import { useEffect } from 'react'
import imagesLoaded from 'imagesloaded'

export default function useScrollToHashEffect() {
  const { asPath } = useRouter()

  useEffect(() => {
    const hash = asPath.split('#')[1]
    const scrollTarget: HTMLElement = document.querySelector(
      `#${hash}[data-is-scroll-to-hash]`
    )

    if (!hash || !scrollTarget) {
      return
    }

    const scrollHash = (): void => scrollTarget.scrollIntoView()

    const imageLoad = imagesLoaded(document.body, scrollHash)
    imageLoad.on('load', scrollHash)

    return () => {
      imageLoad.off('load', scrollHash)
    }
  }, [asPath])
}
