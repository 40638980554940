// PC / MO 디자인표준상 적정 너비
export enum STYLE_BREAKPOINT {
  MOBILE_MIN_WIDTH = '360px',
  MOBILE_MIDDLE_WIDTH = '480px',
  MOBILE_MAX_WIDTH = '1079px',
  MOBILE_MAX_WIDTH_UPPER = '1080px',

  PC_MIN_WIDTH_UNDER = '1199px',
  PC_MIN_WIDTH = '1200px',
  PC_MAX_WIDTH = '1920px',
}

// 모든 속성에 관여하는 Color Set 모음
// fill color/font color 등 Type별 분기가 아닌 단순 '색상값' 기준의 구분
export enum STYLE_COLOR {
  BLACK01 = '#000',
  BLACK02 = '#666',
  BLACK03 = '#808080',
  BLACK04 = '#adadad',
  BLACK05 = '#e5e5e5',

  WHITE01 = '#fff',
  WHITE02_60 = 'rgba(255,255,255,.6)',
  WHITE04_30 = 'rgba(255,255,255,.3)',

  YELLOW = '#FFCD00',

  DARK_BG = '#202237',
  TRANSPARENT = 'transparent',

  PRIMARY01 = '#2C6EDB',
}
