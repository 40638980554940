import { ContentLogPageName } from '@/enums/Content'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { trackPageView, trackUsage } from '@/helpers/logger'

// 메타값 설정이 필요한 뉴스 상세, 공지 상세페이지의 경우 해당페이지에서 로깅
const logPageNameObject = {
  '/': ContentLogPageName.HOME,
  '/story': ContentLogPageName.KAKAOMOBILITY_STORY,
  '/technology': ContentLogPageName.TECHNOLOGY_STORY,
  '/safety': ContentLogPageName.SAFETY_STORY,
  '/customer-support': ContentLogPageName.CS,
  '/ads': ContentLogPageName.ADVERTISEMENT,
  '/autonomous': ContentLogPageName.AUTO,
  '/robotics': ContentLogPageName.ROBOTICS,
  '/privacy-policy': ContentLogPageName.PRIVACY_POLICY,

  '/service-kakaot': ContentLogPageName.KAKAOT,
  '/service-kakaot/taxi': ContentLogPageName.KAKAOT_TAXI,
  '/service-kakaot/driver': ContentLogPageName.KAKAOT_DRIVER,
  '/service-kakaot/bike': ContentLogPageName.KAKAOT_BIKE,
  '/service-kakaot/parking': ContentLogPageName.KAKAOT_PARKING,
  '/service-kakaot/flight': ContentLogPageName.KAKAOT_FLIGHT,
  '/service-kakaot/train': ContentLogPageName.KAKAOT_TRAIN,
  '/service-kakaot/bus': ContentLogPageName.KAKAOT_BUS,
  '/service-kakaot/rentcar': ContentLogPageName.KAKAOT_RENTCAR,
  '/service-kakaot/shuttle': ContentLogPageName.KAKAOT_SHUTTLE,
  '/service-kakaot/global': ContentLogPageName.KAKAOT_GLOBAL,
  '/service-kakaot/guam-taxi': ContentLogPageName.KAKAOT_GUAM_TAXI,
  '/service-kakaot/quick': ContentLogPageName.KAKAOT_QUICK,
  '/service-kakaot/my-car': ContentLogPageName.KAKAOT_MYCAR,
  '/service-kakaot/pet': ContentLogPageName.KAKAOT_PET,
  '/service-business': ContentLogPageName.KAKAOT_BIZ,
  '/service-navi': ContentLogPageName.NAVI,
  '/service-navi/my-car': ContentLogPageName.NAVI_MYCAR,

  '/together': ContentLogPageName.TOGETHER,
  '/together/partner': ContentLogPageName.TOGETHER_PARTNER,
  '/together/local-community': ContentLogPageName.TOGETHER_LOCAL_COMMUNITY,
  '/together/eco-friendly': ContentLogPageName.TOGETHER_ECO_FRIENDLY,

  '/notices': ContentLogPageName.NOTICE,
  '/newsroom': ContentLogPageName.NEWSROOM_ALL,
  '/newsroom/news': ContentLogPageName.NEWSROOM_NEWS,
  '/newsroom/more': ContentLogPageName.NEWSROOM_MORE,
}

export default function useLoggingPageEffect() {
  const { events } = useRouter()

  useEffect(() => {
    let pageEnterTime = null

    const logPageView = () => {
      pageEnterTime = Date.now()
      const pageName = logPageNameObject[window.location.pathname]
      if (!pageName) {
        return
      }
      trackPageView({
        pageActionName: pageName,
      })
    }

    const logUsage = () => {
      const pageName = logPageNameObject[window.location.pathname]
      if (!pageName || !pageEnterTime) {
        return
      }
      trackUsage({
        pageName,
        usage: {
          duration: Date.now() - pageEnterTime,
        },
      })
    }

    logPageView()
    events.on('routeChangeComplete', logPageView)
    events.on('routeChangeStart', logUsage)
    window.addEventListener('pagehide', logUsage)

    return () => {
      events.off('routeChangeComplete', logPageView)
      events.off('routeChangeStart', logUsage)
      window.removeEventListener('pagehide', logUsage)
    }
  }, [events])
}
