import { AxiosResponse } from 'axios'
import createApiCall, { HttpMethod } from '@/helpers/createApiCall'
import { MetaContent } from '@/types/MetaContent'
import { ContentRequestParam, Contents } from '@/types/Content'
import { ApiPath } from '@/constants/Path'

const host = typeof window === 'undefined' ? 'http://0.0.0.0:8080' : ''
export const callJsonDataApi = createApiCall({
  urlPrefix: `${host}`,
  presetHeaders: {
    'Content-Type': 'application/json',
  },
})

export async function callJsonContentApi({
  contentId,
}: ContentRequestParam): Promise<AxiosResponse<Contents>> {
  return await callJsonDataApi<Contents>({
    path: ApiPath.CONTENT(contentId),
    method: HttpMethod.GET,
  })
}

export async function callMetaJsonContentApi(): Promise<
  AxiosResponse<MetaContent>
> {
  return await callJsonDataApi<MetaContent>({
    path: ApiPath.META_CONTENT,
    method: HttpMethod.GET,
  })
}
