import React, { ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
}

export default function OnlyClient({ children }: Props) {
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  return <>{isRendered ? children : null}</>
}
