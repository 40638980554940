import { useEffect, useState } from 'react'

declare global {
  interface Window {
    KakaoAgent: any
  }
}

function useKakaoAgent() {
  const [isUnsupportedBrowser, setIsUnsupportedBrowser] = useState(false)

  useEffect(() => {
    if (!window.KakaoAgent) {
      return
    }

    const Browser = window.KakaoAgent.Browser
    const browser = window.KakaoAgent.getAgent().browser
    const browserVersion = window.KakaoAgent.getAgent().browserVersion
    const isMobile = window.KakaoAgent.getAgent().isMobile

    if (
      (!isMobile &&
        browser !== Browser.Edge &&
        browser !== Browser.Chrome &&
        browser !== Browser.Safari &&
        browser !== Browser.InternetExplorer &&
        browser !== Browser.FireFox) ||
      (browser === Browser.InternetExplorer &&
        browserVersion &&
        parseFloat(browserVersion) <= 10.0)
    ) {
      setIsUnsupportedBrowser(true)
    }
  }, [])

  return {
    isUnsupportedBrowser,
  }
}

export default useKakaoAgent
