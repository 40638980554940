import Script from 'next/script'

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID

function GoogleAnalytics() {
  return (
    <>
      <Script id='google-analytics'>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}', ${
        process.env.BUILD_ENV !== 'production' ? '{debug_mode: true}' : ''
      });
        `}</Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
    </>
  )
}

export default GoogleAnalytics
