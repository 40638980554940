import * as Sentry from '@sentry/nextjs'

// sentry 에러 전송 전 필터링 함수
export const sentryBeforeSend = (
  event: Sentry.Event,
  hint: Sentry.EventHint
): Sentry.Event | null => {
  return event
}

if (process.env.BUILD_ENV) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    tracesSampleRate: process.env.BUILD_ENV === 'production' ? 0.01 : 1.0,
    environment: process.env.BUILD_ENV,
    beforeSend: sentryBeforeSend,
  })
}
