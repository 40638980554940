import { useRouter } from 'next/router'

export default function useContentPathSegments() {
  const { query, pathname } = useRouter()

  if (pathname === '/') {
    return { pathSegments: ['main'] }
  }

  return {
    pathSegments: query?.contents?.[0] ? (query.contents as string[]) : [],
  }
}
