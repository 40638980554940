import { FONT_22_EXTRA_BOLD, FONT_16 } from '@/styles/fonts'
import { STYLE_COLOR } from '@/styles/variables'
import Head from 'next/head'
import React from 'react'
import styled from '@emotion/styled'

function UnsupportedBrowser() {
  return (
    <>
      <Head>
        <title>카카오모빌리티</title>
      </Head>
      <Styled.Wrap>
        <Styled.Container>
          <Styled.Title>죄송합니다</Styled.Title>
          <Styled.Description>
            현재 브라우저 버전을 지원하지 않습니다.
            <br />
            원활한 서비스 이용을 위해 Chrome, Safari ,Firefox <br />
            또는 Internet Explorer 11 이상의 버전을 사용해주세요
          </Styled.Description>
        </Styled.Container>
      </Styled.Wrap>
    </>
  )
}

const Styled = {
  Wrap: styled.div`
    display: table;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  Container: styled.div`
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  `,
  Title: styled.b`
    ${FONT_22_EXTRA_BOLD};
  `,
  Description: styled.p`
    margin-top: 8px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
  `,
}

export default UnsupportedBrowser
