import { IconTypeFile } from '@/components/Icon'
import { LocalStorageKey } from '@/enums/LocalStorage'
import {
  localStorageGet,
  localStorageSet,
  NoticeStorageType,
} from '@/helpers/localStorageHelper'
import {
  STYLE_FILL_BUTTON_BLACK,
  STYLE_OUTLINE_BUTTON_DEFAULT,
} from '@/styles/buttons'
import { FONT_16, FONT_18, FONT_22 } from '@/styles/fonts'
import {
  BackgroundPathType,
  STYLE_SAFE_BACKGROUND_IMAGE,
} from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  MetaNotice,
  MetaNoticeList,
  NoticeArticleType,
} from '@/types/MetaContent'
import {
  BodyScrollOptions,
  disableBodyScroll as disable,
  enableBodyScroll as enable,
} from 'body-scroll-lock'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AppNoticeAction, AppNoticeType } from '@/types/Context'
import { trackPageView, trackUsage } from '@/helpers/logger'
import { ContentLogPageName } from '@/enums/Content'
import { useLeavePageListener } from '@/hooks/useLeavePageListener'
import FocusLock from 'react-focus-lock'
import { hideOthers } from 'aria-hidden'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const marked = require('marked')

// scroll-lock
const disableBodyScroll = (
  targetElement: HTMLElement | Element,
  options?: BodyScrollOptions
): void => {
  const { pageYOffset } = window
  window.scrollTo(0, 0)
  document.body.dataset.bodyScrollPos = String(pageYOffset)
  document.body.style.cssText = `
      position: fixed;
      min-width: 100%;
      margin-top: -${pageYOffset}px;
    `
  disable(targetElement, options)
}

const enableBodyScroll = (targetElement: HTMLElement): void => {
  document.body.style.removeProperty('position')
  document.body.style.removeProperty('min-width')
  document.body.style.removeProperty('margin-top')
  document.body.style.removeProperty('overflow')
  window.scrollTo(
    0,
    Number(document.body.dataset.bodyScrollPos) || window.pageYOffset
  )
  enable(targetElement)
}

interface NoticeStyledProps {
  noticeWidth?: string | number
  noticeHeight?: string | number
}

const renderNotice = (notice: MetaNotice): boolean => {
  // 최우선 hidden condition
  // 1. hidden flag = true
  // 2. startDate - undefined - (eventHandler 로 뜨는 경우로 판단)
  if (notice.hidden || !notice?.startDate) {
    return false
  }

  // exp date 검증
  const storage = localStorageGet(LocalStorageKey.NOTICE_EXP)
  const isExpiry = ((): boolean => {
    if (Array.isArray(storage)) {
      return Date.now() < storage.find((v) => notice.id === v.id)?.exp
    }
    return false
  })()

  // 노출 date 검증
  const date = ((): boolean => {
    if (notice?.startDate) {
      const now = Date.now()
      const start = new Date(notice?.startDate).getTime()
      const end = notice?.endDate ? new Date(notice?.endDate).getTime() : now
      return now >= start && now <= end
    }
    return false
  })()

  return !isExpiry && date
}

const saveClose = (value: NoticeStorageType): void => {
  localStorageSet(LocalStorageKey.NOTICE_EXP, value)
}

const Partial = {
  Title({ title }: { title: string }) {
    if (!title) return null
    return <Styled.Title>{title}</Styled.Title>
  },
  Article({
    article,
    type = 'text',
  }: {
    article: string
    type: NoticeArticleType
  }) {
    if (!article) return null
    return (
      <>
        {type === 'text' && <Styled.Article>{article}</Styled.Article>}
        {type === 'markdown' && (
          <Styled.Article
            dangerouslySetInnerHTML={{
              __html: marked.parse(article),
            }}
          />
        )}
        {(type === 'html' || type === 'image') && (
          <Styled.Article
            type={type}
            dangerouslySetInnerHTML={{ __html: article }}
          />
        )}
      </>
    )
  },
}

interface NoticeProps {
  noticeList: MetaNoticeList
  notice: AppNoticeType
  noticeAction: AppNoticeAction
  contentId: string
}

function Notice({ noticeList, notice, noticeAction, contentId }: NoticeProps) {
  const [filteredNotice, setFilteredNotice] = useState([])
  const init = useRef(false)
  const [visible, setVisible] = useState(false)

  const noticeRef = useRef(null)
  const noticeContainerRef = useRef(null)

  const onClose = useCallback(
    ({ notice, exp }): void => {
      if (exp) {
        saveClose({ notice, notices: noticeList })
      }

      const showNoticeList = filteredNotice.filter(
        (item) => item.id !== notice.id
      )
      setFilteredNotice(showNoticeList)
      setVisible(Boolean(showNoticeList.length))

      noticeContainerRef.current.scrollTop = 0
      noticeAction.setNotice(null)
    },
    [filteredNotice, noticeAction, noticeList]
  )

  useEffect(() => {
    if (!init.current && noticeList) {
      const showNoticeList = noticeList.filter((item) => {
        const { contentId: noticeCategory } = item
        return noticeCategory?.includes(contentId) && renderNotice(item)
      })
      setFilteredNotice(showNoticeList)
      setVisible(() => Boolean(showNoticeList.length))
      init.current = true
    }
    return () => {
      setFilteredNotice([])
      setVisible(false)
      init.current = false
    }
  }, [contentId, noticeList])

  useEffect(() => {
    if (notice) {
      const targetNotice = noticeList.find((item) => notice === item.id)

      if (!targetNotice) {
        noticeAction.setNotice(null)
        return
      }

      if (!targetNotice?.hidden) {
        setFilteredNotice((prevNotice) => {
          return [...prevNotice, targetNotice]
        })
        setVisible(true)
      }
    }
    return () => {
      noticeAction.setNotice(null)
    }
  }, [notice, noticeAction, noticeList])

  useEffect(() => {
    if (visible) {
      noticeContainerRef.current.scrollTop = 0

      disableBodyScroll(document.body, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('data-scroll-lock-ignore') !== null) {
              return true
            }
            el = el.parentElement
          }
        },
      })

      return () => {
        enableBodyScroll(document.body)
      }
    }
  }, [visible])

  useEffect(() => {
    if (visible && noticeRef.current) {
      const undo = hideOthers(noticeRef.current)
      return undo
    }
  }, [visible])

  return visible ? (
    <FocusLock returnFocus>
      <Styled.Root ref={noticeRef} data-scroll-lock-ignore visible={visible}>
        <Styled.Action>
          <Styled.AllCloseButton
            onClick={() => {
              setFilteredNotice([])
              setVisible(false)
              init.current = false
            }}
          >
            전체 닫기
          </Styled.AllCloseButton>
        </Styled.Action>
        <Styled.Container ref={noticeContainerRef}>
          {filteredNotice?.map((item: MetaNotice) => (
            <NoticeItem key={item.id} item={item} onClose={onClose} />
          ))}
        </Styled.Container>
      </Styled.Root>
    </FocusLock>
  ) : null
}

function NoticeItem({
  item,
  onClose,
}: {
  item: MetaNotice
  onClose: ({ notice, exp }: { notice: any; exp: any }) => void
}) {
  const { leavePageListener } = useLeavePageListener()

  useEffect(() => {
    const pageEnterTime = Date.now()
    trackPageView({
      pageName: ContentLogPageName.POPUP,
      pageMeta: { id: item.id },
    })

    const unsubscribe = leavePageListener(() => {
      trackUsage({
        pageName: ContentLogPageName.POPUP,
        usage: { duration: Date.now() - pageEnterTime },
      })
    })

    return unsubscribe
  }, [item.id, leavePageListener])

  return (
    <Styled.Notice
      key={`Notice-${item.id}`}
      noticeWidth={item?.width}
      noticeHeight={item?.height}
    >
      <Partial.Title title={item?.title} />
      <Partial.Article article={item.article} type={item?.type} />
      <Styled.ButtonGroup>
        {item.exp && (
          <Styled.Button onClick={() => onClose({ notice: item, exp: true })}>
            {item.expLabel}
          </Styled.Button>
        )}
        <Styled.Button onClick={() => onClose({ notice: item, exp: false })}>
          {item?.type === 'image' ? '닫기' : '확인'}
        </Styled.Button>
      </Styled.ButtonGroup>
    </Styled.Notice>
  )
}

const Styled = {
  Root: styled.div<{ visible: boolean }>`
    display: none;
    ${({ visible }) =>
      visible &&
      css`
        display: flex;
      `}
    flex-direction:column;
    box-sizing: border-box;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 3100;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-text-size-adjust: none;

    &:after {
      content: '';
      height: 16vh;
      flex: 0 1 auto;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0;
      &:after {
        display: none;
      }
    }
  `,
  Action: styled.div`
    flex: 0 0 auto;
    box-sizing: border-box;
    height: 16vh;
    padding: 49px 60px 0;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: 76px;
      padding: 28px 0 0;
    }
  `,
  AllCloseButton: styled.button`
    position: fixed;
    right: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-left: auto;
    ${FONT_16};
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      margin-left: 6px;
      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_24_CLOSE_WHITE,
        24,
        24,
        BackgroundPathType.ICONS
      )};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      right: 6px;
      padding: 12px;
    }
  `,
  Container: styled.div`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:after {
      flex: 0 0 auto;
      display: block;
      content: '';
      height: 20px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-shrink: 1;
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start;
    }
  `,
  Notice: styled.div<NoticeStyledProps>`
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: calc(100vh - 40px);
    max-width: 620px;
    min-width: 320px;
    margin: 20px auto 0;
    padding: 32px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};

    ${({ noticeWidth }) =>
      noticeWidth &&
      `width: ${noticeWidth}${typeof noticeWidth === 'number' && 'px'};`}

    ${({ noticeHeight }) =>
      noticeHeight &&
      `height: ${noticeHeight}${typeof noticeHeight === 'number' && 'px'};`}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-shrink: 0;
      width: 90%;
      max-height: none;
    }
  `,

  Title: styled.h1`
    padding-bottom: 20px;
    ${FONT_22};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      ${FONT_18}
    }
  `,

  Article: styled.div<{ type?: NoticeArticleType }>`
    flex-grow: 1;
    overflow: auto;
    margin: 0 -32px 32px;
    padding: 0 32px;
    ${FONT_16};
    white-space: pre-line;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${FONT_22};
      color: ${STYLE_COLOR.BLACK01};
      @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
        ${FONT_18}
      }
    }

    strong,
    b {
      font-weight: 700;
      color: ${STYLE_COLOR.BLACK01};
    }

    img {
      max-width: 100%;
    }

    ${({ type }) =>
      (type === 'html' || type === 'markdown') &&
      css`
        word-break: break-all;
        white-space: normal;
        line-height: 1.2;
        p {
          margin-bottom: 20px;
          white-space: pre-line;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 20px;
        }

        ul,
        ol {
          padding-left: 20px;
          margin: 20px 0;
        }

        ul {
          list-style: disc;
        }

        ol {
          list-style: decimal;
        }
      `}

    ${({ type }) =>
      type === 'image' &&
      css`
        padding: 0;
        margin-bottom: 20px;
        margin-top: -32px;
        .screen-out {
          overflow: hidden;
          position: absolute;
          z-index: -1;
          width: 1px;
          height: 1px;
          border: 0;
          clip: rect(1px, 1px, 1px, 1px);
          clip-path: inset(50%);
        }
        & > a {
          display: block;
        }
        img {
          max-width: 100%;
        }
      `}
  `,

  ButtonGroup: styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    gap: 8px;
    justify-content: center;
    align-items: flex-end;
    margin: 0 -12px -12px;
  `,

  Button: styled.button`
    ${STYLE_OUTLINE_BUTTON_DEFAULT}
    ${FONT_16}
    flex: 1;
    box-sizing: content-box;
    padding: 8px 20px;
    text-align: center;
    white-space: nowrap;
    & + & {
      ${STYLE_FILL_BUTTON_BLACK}
      padding:9px 21px;
    }
  `,
}

export default Notice
