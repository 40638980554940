import { LocalStorageKey } from '@/enums/LocalStorage'
import { MetaNoticeList, NoticeExpType } from '@/types/MetaContent'
export interface NoticeStorageType {
  notice: NoticeExpType
  notices: MetaNoticeList
}

type LocalStorageValue = NoticeStorageType

export const localStorageSet = (
  key: LocalStorageKey,
  item: LocalStorageValue
): null => {
  switch (key) {
    case LocalStorageKey.NOTICE_EXP: {
      // exp date 설정(setter)
      const { notice, notices } = item
      const date = new Date()
      const exp = date.setDate(date.getDate() + notice.exp)
      const storageValues = localStorageGet(LocalStorageKey.NOTICE_EXP)
      const metaNoticeId = notices.map((notice) => notice.id)

      const addedValues = [
        ...storageValues.filter(({ id, exp }: { id: string; exp: number }) => {
          return exp > Date.now() && metaNoticeId.includes(id)
        }),
        { id: notice.id, exp },
      ]

      localStorage.setItem(key, JSON.stringify(addedValues))
      break
    }
    default:
      return null
  }
}

export const localStorageGet = (key: LocalStorageKey) => {
  const value = localStorage.getItem(key)

  switch (key) {
    case LocalStorageKey.NOTICE_EXP: {
      try {
        return JSON.parse(value) ?? []
      } catch (error) {
        return []
      }
    }
    default:
      return null
  }
}
