import {
  trackEvent as tuiTrackEvent,
  TrackEventOption,
  trackPageView as tuiTrackPageView,
  TrackPageViewOption,
  trackUsage as tuiTrackUsage,
  TrackUsageOption,
} from '@kakaomobility/tui-core'

export function trackPageView(params: TrackPageViewOption) {
  if (!params.pageName && !params.pageActionName) return

  tuiTrackPageView(params)
}

export function trackUsage(params: TrackUsageOption) {
  if (!params.pageName) return

  const usage = Object.assign(getUsageParam(), params.usage)
  tuiTrackUsage({ ...params, usage })
}

export function trackEvent(params: TrackEventOption) {
  if (!params.pageName || !params.actionName) return
  tuiTrackEvent(params)
}

function getUsageParam() {
  const scrollEl = window.document.documentElement
  const scrollHeight = scrollEl.scrollHeight
  const scrollTop = scrollEl.scrollTop
  const scrollInnerHeight = scrollEl.clientHeight
  const scrollBottom = scrollTop + scrollInnerHeight
  const scrollPercent =
    scrollTop === 0
      ? 0
      : Math.round((scrollTop / (scrollHeight - scrollInnerHeight)) * 100)
  return {
    scroll_bottom: scrollBottom,
    scroll_height: scrollHeight,
    scroll_inner_height: scrollInnerHeight,
    scroll_percent: String(scrollPercent),
    scroll_top: String(scrollTop),
  }
}
