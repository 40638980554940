import React, {
  Context,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  AppConfigAction,
  AppConfigType,
  AppContextType,
  AppNoticeAction,
} from '@/types/Context'

const AppContext = createContext(undefined) as Context<AppContextType>
AppContext.displayName = 'AppContext'

const { Provider } = AppContext

interface Props {
  children: ReactNode
}

export function AppProvider({ children }: Props) {
  /**
   * configState
   */
  const [config, setConfig] = useState<AppConfigType>({
    intro: false,
    playIntroVideo: false,
    hasEnteredFullscreenIntro: false,
  })

  const configAction: AppConfigAction = useMemo(() => {
    return {
      setIntro(intro = true) {
        return setConfig((prevState) => {
          if (prevState?.intro && prevState.intro === intro) {
            return prevState
          }
          return {
            ...prevState,
            intro,
          }
        })
      },
      setPlayIntroVideo(playIntroVideo = true) {
        return setConfig((prevState) => {
          if (
            prevState?.playIntroVideo &&
            prevState.playIntroVideo === playIntroVideo
          ) {
            return prevState
          }
          return {
            ...prevState,
            playIntroVideo,
          }
        })
      },
      setHasEnteredFullScreenIntro(value = true) {
        return setConfig((prevState) => ({
          ...prevState,
          hasEnteredFullscreenIntro: value,
        }))
      },
    }
  }, [setConfig])

  const [notice, setNoticeTarget] = useState<string>(null)
  const noticeAction = useMemo((): AppNoticeAction => {
    function setNotice(id: string) {
      return setNoticeTarget(id)
    }
    return {
      setNotice,
    }
  }, [])

  return (
    <Provider
      value={{
        config,
        configAction,
        notice,
        noticeAction,
      }}
    >
      {children}
    </Provider>
  )
}

export { AppContext }

export default function useAppContext(): AppContextType {
  return useContext(AppContext)
}
