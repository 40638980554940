import React, { useMemo } from 'react'
import Notice from '@/components/Notice'
import useAppContext from '@/contexts'
import useMetaContent from '@/hooks/fetch/useMetaContent'
import useGetPopups from '@/hooks/fetch/useGetPopups'
import { AdminPopup, MetaNotice } from '@/types/MetaContent'
import useContentPathSegment from '@/hooks/useContentPathSegments'

const convertPopupToNotice = (popup: AdminPopup): MetaNotice => {
  return {
    id: String(popup.id),
    type: 'html',
    contentId: ['main'],
    order: popup.order && Number(popup.order),
    title: popup.title,
    article: popup.content,
    width: 600,
    height: 620,
    // api 에서 노출 가능한 조건의 팝업만 내려주기 때문에 무조건 실행가능한 조건의 값 설정 (startDate, endDate)
    startDate: '2023-01-01T00:00:00',
    endDate: '9999-01-01T00:00:00',
    exp: popup?.expired_days && Number(popup?.expired_days),
    expLabel: popup?.expired_label,
  }
}

export default function NoticeContainer() {
  const { meta } = useMetaContent()
  const { popups = [] } = useGetPopups()
  const { notice, noticeAction } = useAppContext()
  const { pathSegments } = useContentPathSegment()

  const noticeList = useMemo(() => {
    return [...popups.map(convertPopupToNotice), ...(meta?.notices || [])]
  }, [meta, popups])

  return (
    <Notice
      noticeList={noticeList}
      notice={notice}
      noticeAction={noticeAction}
      contentId={pathSegments[0]}
    />
  )
}
