import { css } from '@emotion/react'
import {
  FONT_16_BOLD,
  FONT_22,
  FONT_22_EXTRA_BOLD,
  FONT_18,
} from '@/styles/fonts'
import { STYLE_COLOR, STYLE_BREAKPOINT } from '@/styles/variables'
import { IconTypeFile } from '@/components/Icon'
import {
  BackgroundPathType,
  STYLE_SAFE_BACKGROUND_IMAGE,
} from '@/styles/presets'

export const STYLE_INIT_BUTTON = css`
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0 none;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  color: ${STYLE_COLOR.BLACK01};
`

export const STYLE_OUTLINE_BUTTON_DEFAULT = css`
  ${STYLE_INIT_BUTTON};
  padding: 9px 20px;
  border: 1px solid ${STYLE_COLOR.BLACK03};
  ${FONT_16_BOLD};
`

export const STYLE_OUTLINE_BUTTON = css`
  ${STYLE_OUTLINE_BUTTON_DEFAULT};
`

export const STYLE_FILL_BUTTON_BLACK = css`
  ${STYLE_INIT_BUTTON};
  ${FONT_16_BOLD};
  padding: 10px 21px;
  background-color: ${STYLE_COLOR.BLACK01};
  color: ${STYLE_COLOR.WHITE01};
`

const STYLE_OUTLINE_BUTTON_WITH_ICON = css`
  ${STYLE_OUTLINE_BUTTON_DEFAULT};

  &:after {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    margin-left: 45px;
  }
`

export const STYLE_OUTLINE_BUTTON_DOWNLOAD = css`
  ${STYLE_OUTLINE_BUTTON_WITH_ICON};

  &:after {
    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_16_DOWNLOAD,
      16,
      16,
      BackgroundPathType.ICONS
    )};
  }
`

export const STYLE_OUTLINE_BUTTON_LINK = css`
  ${STYLE_OUTLINE_BUTTON_WITH_ICON};
  display: flex;
  align-items: center;
  width: 240px;
  margin-top: 20px;

  &:after {
    margin-left: auto;

    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_16_MORE_SMALL,
      16,
      16,
      BackgroundPathType.ICONS
    )};
  }

  @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`

const STYLE_ARROW_BUTTON_DEFAULT = css`
  ${STYLE_INIT_BUTTON};
  ${FONT_16_BOLD};

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px;
    transition: margin-left 0.2s ease-out;
  }

  &:hover {
    color: ${STYLE_COLOR.YELLOW};

    &:after {
      margin-left: 24px;
      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_MORE_YELLOW,
        40,
        16,
        BackgroundPathType.ICONS
      )};
    }
  }
`

export const STYLE_ARROW_BUTTON_BLACK = css`
  ${STYLE_ARROW_BUTTON_DEFAULT};

  &:after {
    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_16_MORE,
      40,
      16,
      BackgroundPathType.ICONS
    )};
    margin-top: -0.1em;
  }
`

export const STYLE_ARROW_BUTTON_WHITE = css`
  ${STYLE_ARROW_BUTTON_DEFAULT};
  color: ${STYLE_COLOR.WHITE01};

  &:after {
    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_16_MORE_WHITE,
      40,
      16,
      BackgroundPathType.ICONS
    )};
  }
`

export const STYLE_STORE_BUTTON_DEFAULT = css`
  ${STYLE_INIT_BUTTON};
  width: 220px;
  height: 86px;
  padding: 26px 0;
  border: 1px solid ${STYLE_COLOR.BLACK01};
  ${FONT_22};
  transition: all 0.1s ease-in;

  @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    ${FONT_18}
  }

  &:hover {
    background-color: ${STYLE_COLOR.YELLOW};
    border: 1px solid ${STYLE_COLOR.YELLOW};
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 8px;
  }
`

export const STYLE_APPSTORE_BUTTON = css`
  ${STYLE_STORE_BUTTON_DEFAULT};

  &:before {
    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_32_APPSTORE,
      32,
      32,
      BackgroundPathType.ICONS
    )};
  }
`

export const STYLE_GOOGLEPLAY_BUTTON = css`
  ${STYLE_STORE_BUTTON_DEFAULT};

  &:before {
    ${STYLE_SAFE_BACKGROUND_IMAGE(
      IconTypeFile.IC_32_GOOGLEPLAY,
      32,
      32,
      BackgroundPathType.ICONS
    )};
  }
`

export const STYLE_OUTLINE_BUTTON_LARGE = css`
  ${STYLE_INIT_BUTTON};
  width: auto;
  height: 86px;
  padding: 26px 36px;
  ${FONT_22_EXTRA_BOLD};
  border: 1px solid ${STYLE_COLOR.BLACK01};
`
