import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios'

interface CreateApiCallParam<H = {}> {
  urlPrefix: string
  presetHeaders?: H
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
}

export interface CallApiParam<
  PathType extends string = string,
  DataType = unknown
> extends AxiosRequestConfig {
  path: PathType
  method: Method
  data?: DataType
}

function createApiCall<PathType extends string>({
  urlPrefix,
  presetHeaders = {},
}: CreateApiCallParam) {
  return async function apiCall<APIResponse = unknown, APIRequest = unknown>({
    path,
    method,
    params,
    data,
    paramsSerializer,
    headers,
  }: CallApiParam<PathType, APIRequest>): Promise<AxiosPromise<APIResponse>> {
    const url = urlPrefix + path
    return await axios({
      url,
      method,
      params,
      data,
      paramsSerializer,
      headers: {
        ...presetHeaders,
        ...headers,
        'Access-Control-Allow-Origin': '*',
      },
    })
  }
}

export default createApiCall
