import queryString from 'query-string'
import {
  GetFixedNoticesParams,
  GetNoticeTotalCountParams,
  GetNoticesParams,
} from '@/apis/noticeData'

const jsonPrefix = process.env.JSON_DATA_API_URI
const mobDataPrefix = process.env.MOB_DATA_API_URI
const noticeDataPrefix = process.env.NOTICE_DATA_API_URI

export const ApiPath = {
  CONTENT: (contentId: number | string) =>
    `${jsonPrefix}content.${contentId}.json`,
  META_CONTENT: `${jsonPrefix}metacontent.json`,
  MOB_DATA_TOTAL_USER: `${mobDataPrefix}users/total`,
  MOB_DATA_DAILY_DISTANCE: `${mobDataPrefix}distances/daily`,

  GET_POPUPS: `${noticeDataPrefix}popups`,
  GET_NOTICES: (params: GetNoticesParams) =>
    `${noticeDataPrefix}v2/notices?${queryString.stringify(params, {
      arrayFormat: 'bracket',
    })}`,
  GET_FIXED_NOTICES: (params: GetFixedNoticesParams) =>
    `${noticeDataPrefix}v2/notices/fixed_top?${queryString.stringify(params, {
      arrayFormat: 'bracket',
    })}`,
  GET_NOTICE_DETAIL: (noticeId: number) => `${noticeDataPrefix}${noticeId}`,
  GET_NOTICE_TOTAL_COUNT: (params: GetNoticeTotalCountParams) =>
    `${noticeDataPrefix}notices/total?${queryString.stringify(params, {
      arrayFormat: 'bracket',
    })}`,
}
