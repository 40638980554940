import { useRouter } from 'next/router'
import { useCallback } from 'react'

// 라우터 이동 또는 페이지 종료시 호출되는 리스너. 주로 usage로깅에 활용
export function useLeavePageListener() {
  const router = useRouter()

  const leavePageListener = useCallback(
    (onLeavePage: () => void) => {
      router.events.on('routeChangeStart', onLeavePage)
      window.addEventListener('pagehide', onLeavePage)

      return () => {
        router.events.off('routeChangeStart', onLeavePage)
        window.removeEventListener('pagehide', onLeavePage)
      }
    },
    [router.events]
  )

  return { leavePageListener }
}
