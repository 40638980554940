import { ApiPath } from '@/constants/Path'
import createApiCall, { HttpMethod } from '@/helpers/createApiCall'
import { NoticeType, NoticeList, Notice } from '@/types/Notice'
import { AxiosError } from 'axios'

const host = typeof window === 'undefined' ? 'http://0.0.0.0:8080' : ''
export const callNoticeDataApi = createApiCall({
  urlPrefix: host,
  presetHeaders: {
    'Content-Type': 'application/json',
  },
})

export const getPopups = async () => {
  return await callNoticeDataApi<any, AxiosError>({
    path: ApiPath.GET_POPUPS,
    method: HttpMethod.GET,
  })
}

export interface GetNoticesParams {
  notice_types: NoticeType[]
  offset?: number
  size?: number
}

export type GetFixedNoticesParams = Omit<GetNoticesParams, 'offset'>

export const getNotices = async (params: GetNoticesParams) => {
  return await callNoticeDataApi<NoticeList, AxiosError>({
    path: ApiPath.GET_NOTICES(params),
    method: HttpMethod.GET,
  })
}

export const getFixedNotices = async (params: GetFixedNoticesParams) => {
  return await callNoticeDataApi<NoticeList, AxiosError>({
    path: ApiPath.GET_FIXED_NOTICES(params),
    method: HttpMethod.GET,
  })
}

export const getNoticeDetail = async (noticeId: number) => {
  return await callNoticeDataApi<Notice, AxiosError>({
    path: ApiPath.GET_NOTICE_DETAIL(noticeId),
    method: HttpMethod.GET,
  })
}

export interface GetNoticeTotalCountParams {
  notice_types: NoticeType[]
}

export const getNoticeTotalCount = async (
  params: GetNoticeTotalCountParams
) => {
  return await callNoticeDataApi<any, AxiosError>({
    path: ApiPath.GET_NOTICE_TOTAL_COUNT(params),
    method: HttpMethod.GET,
  })
}
