import { AxiosError } from 'axios'
import { callMetaJsonContentApi } from '@/apis/jsonData'
import useSWR from 'swr'
import { ApiPath } from '@/constants/Path'
import { MetaContent } from '@/types/MetaContent'

function useMetaContent() {
  const { data, error, isLoading } = useSWR<MetaContent, AxiosError>(
    ApiPath.META_CONTENT,
    async () => (await callMetaJsonContentApi()).data
  )

  return { meta: data, error, isLoading }
}

export default useMetaContent
